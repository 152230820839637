import { rewardsAxios } from "utils";
import * as routes from "./routes";
import { Referrer } from "@/types/register";

export const postRegisterGoogle = (body: {
  grant_type: string;
  ip_address: string;
  user_agent: string;
  id_token?: string;
  promocode?: string;
  client_id: string;
  click_id: string;
  utm_source: string;
  utm_medium: string;
  utm_campaign: string;
  oid: string;
  platform: string;
}) =>
  rewardsAxios
    .post(routes.POST_REGISTER, body, {
      headers: {},
    })
    .then((res) => res);

export const postRegisterApple = (body: {
  grant_type: string;
  ip_address: string;
  user_agent: string;
  id_token?: string;
  promocode?: string;
  client_id: string;
  click_id: string;
  utm_source: string;
  utm_medium: string;
  utm_campaign: string;
  oid: string;
  user_identity: string;
  platform: string;
}) =>
  rewardsAxios
    .post(routes.POST_REGISTER, body, {
      headers: {},
    })
    .then((res) => res);

export const postRegisterPassword = (body: {
  grant_type: string;
  ip_address: string;
  user_agent: string;
  promocode?: string;
  user: {
    email: string;
    password: string;
    first_name: string;
    last_name: string;
    client_id: string;
    click_id: string;
    utm_source: string;
    utm_medium: string;
    utm_campaign: string;
    oid: string;
  };
  platform: string;
}) =>
  rewardsAxios
    .post(routes.POST_REGISTER, body, {
      headers: {},
    })
    .then((res) => res);

export const getReferrer = (params: { promocode: string }) =>
  rewardsAxios
    .get(routes.GET_REFERRER, {
      headers: {},
      params,
    })
    .then((res) => res.data.data as Referrer);

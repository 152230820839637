import { HTMLInputTypeAttribute, ReactNode, FC } from "react";
import { twMerge } from "tailwind-merge";
import Button from "@/components/Buttons/Primary/Button";
import { useTranslations } from "next-intl";

type Props = {
  className?: string;
  inputClassName?: string;
  label?: string;
  id?: string;
  placeholder?: string;
  autoComplete?: string;
  name?: string;
  type?: HTMLInputTypeAttribute;
  value?: string;
  onChange?: (e: any) => void;
  onFocus?: (e: any) => void;
  onBlur?: (e: any) => void;
  onKeyDown?: (e: any) => void;
  onKeyUp?: (e: any) => void;
  error?: any;
  disabled?: boolean;
  icon?: ReactNode;
  iconClassName?: string;
  readOnly?: boolean;
  isHighlighted?: boolean;
  isButtonLoading?: boolean;
  buttonClassName?: string;
  labelClassName?: string;
  buttonLabel?: string;
  onButtonClick?: () => void;
};

const TextInput: FC<Props> = ({
  className,
  inputClassName,
  iconClassName,
  labelClassName,
  autoComplete,
  label,
  onFocus,
  onBlur,
  id,
  name,
  readOnly,
  type = "text",
  placeholder,
  value,
  onChange,
  onKeyDown,
  onKeyUp,
  error,
  buttonClassName,
  disabled = false,
  isHighlighted = false,
  buttonLabel,
  onButtonClick,
  isButtonLoading,
  icon,
}) => {
  const t = useTranslations();

  return (
    <div className={twMerge("transition-all min-w-[200px] w-full", className)}>
      {label && (
        <label
          htmlFor={id}
          className={twMerge(
            "block font-semibold text-[14px] pb-2",
            isHighlighted && "!text-primary",
            labelClassName,
          )}
        >
          {label}
        </label>
      )}
      <div className="relative">
        <input
          className={twMerge(
            "peer h-[40px] bg-white border border-black-300 placeholder:text-black-400 rounded-[8px] p-[8px_12px] text-[16px] md:text-[14px] w-full transition-colors duration-100 !outline-0 !outline-offset-0 truncate",
            isHighlighted && "border-primary",
            error && "border-red",
            !readOnly &&
              "hover:border-primary hover:outline hover:!outline-[1px] hover:outline-primary",
            !readOnly &&
              "focus:border-primary focus:outline focus:!outline-[1px] focus:outline-primary",
            onButtonClick && "pr-[110px]",
            disabled &&
              "!bg-black-200 !border !border-black-300 !text-black-400 cursor-not-allowed !outline-none",
            !!icon && "pl-[44px]",
            inputClassName,
          )}
          id={id}
          type={type}
          autoComplete={autoComplete}
          name={name}
          placeholder={placeholder}
          onKeyDown={onKeyDown}
          onKeyUp={onKeyUp}
          onFocus={onFocus}
          onBlur={onBlur}
          value={value}
          onChange={onChange}
          disabled={disabled}
          readOnly={readOnly}
        />
        {icon && (
          <div
            className={twMerge(
              "absolute flex items-center justify-center z-10 top-[50%] translate-y-[-50%] h-[24px] w-[24px] left-[12px] pointer-events-none",
              iconClassName,
            )}
          >
            {icon}
          </div>
        )}
        {onButtonClick && (
          <Button
            className={twMerge(
              "!absolute py-[8px] px-[16px] z-10 top-[2px] right-[2px] cursor-pointer",
              buttonClassName,
            )}
            label={t(buttonLabel)}
            onClick={onButtonClick}
            isLoading={isButtonLoading}
          />
        )}
      </div>
    </div>
  );
};

export default TextInput;

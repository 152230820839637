import { rewardsAxios } from "utils";
import * as routes from "./routes";

export const postLoginEmail = (body: {
  user: { email: string; password: string };
}) =>
  rewardsAxios
    .post(routes.POST_LOGIN_EMAIL, body, {
      headers: {},
    })
    .then((res) => res);

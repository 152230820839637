import { twMerge } from "tailwind-merge";
import { FC, ReactNode } from "react";

type Props = {
  className?: string;
  label: string;
  onClick?: () => void;
  isLoading?: boolean;
  isDisabled?: boolean;
  beforeIcon?: ReactNode;
  afterIcon?: ReactNode;
  type?: "button" | "submit" | "reset" | undefined;
};

const Button: FC<Props> = ({
  className = "",
  label,
  isLoading,
  beforeIcon,
  afterIcon,
  onClick,
  isDisabled = false,
  type,
}) => {
  return (
    <button
      type={type}
      title={label}
      aria-label={label}
      disabled={isDisabled || isLoading}
      className={twMerge(
        "relative group transition-all flex items-center justify-center bg-transparent text-primary hover:text-primary-400 gap-[10px] text-[16px] font-bold",
        isDisabled && "opacity-50 hover:!text-primary cursor-not-allowed",
        isLoading && "cursor-not-allowed",
        className,
      )}
      onClick={onClick}
    >
      {!isLoading && beforeIcon}
      <span className={twMerge("truncate", isLoading && "invisible")}>
        {label}
      </span>
      {!isLoading && afterIcon}
    </button>
  );
};

export default Button;

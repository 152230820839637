import { twMerge } from "tailwind-merge";
import { FunctionComponent } from "react";
import Warning from "@/public/icons/warning.svg";

type Props = { className?: string; error: string };

const ErrorMessage: FunctionComponent<Props> = ({ className, error }) => {
  return (
    <div
      className={twMerge(
        "flex items-start gap-[5px] text-red text-[12px]",
        className,
      )}
    >
      <Warning className="relative top-[3px] h-12px w-12px" />
      {error}
    </div>
  );
};

export default ErrorMessage;

import { AuthOptions } from "next-auth";
import CredentialsProvider from "next-auth/providers/credentials";
import {
  postRegisterApple,
  postRegisterGoogle,
  postRegisterPassword,
} from "@/sources/register/api";
import { postLoginEmail } from "@/sources/login/api";
import GoogleProvider from "next-auth/providers/google";
import AppleProvider from "next-auth/providers/apple";

export const ResponsiveBreakPoints = {
  mobileBreakPoint: "500px",
  smallTabletBreakPoint: "900px",
  tabletBreakPoint: "991px",
  desktopBreakPoint: "992px",
};

export const APP_STATISTICS_COUNTER = 1200000;
export const EMAIL_REGEX = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
export const DEBOUNCE_LATENCY = 500;
export const LETTER_NUMBERS_REGEX = /^(?=.*[a-zA-Z])(?=.*\d).+$/;
export const LETTER_NUMBERS_SPECIAL_CHARACTER_REGEX =
  /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[^a-zA-Z\d]).+$/;
export const LOWERCASE_REGEX = /[a-z]/;
export const UPPERCASE_REGEX = /[A-Z]/;
export const NUMERIC_REGEX = /[0-9]/;
export const SPECIAL_REGEX = /[!@#$%^&*(),.?":{}|<>]/;

export const authOptions: AuthOptions = {
  providers: [
    CredentialsProvider({
      name: "Credentials",
      credentials: {
        email: { label: "Email", type: "text" },
        password: { label: "Password", type: "password" },
      },
      async authorize(credentials, req) {
        // @ts-ignore
        const ipAddress = req.headers["cf-connecting-ip"];
        // @ts-ignore
        const userAgent = req.headers["user-agent"];

        if (typeof credentials !== "undefined") {
          if (req.query?.provider === "apple") {
            try {
              const { data, headers } = await postRegisterApple({
                grant_type: req.query?.provider,
                ip_address: ipAddress,
                user_agent: userAgent,
                id_token: req.query?.id_token,
                promocode: req.query?.promocode,
                client_id: req.query?.client_id,
                click_id: req.query?.click_id,
                utm_source: req.query?.utm_source,
                utm_medium: req.query?.utm_medium,
                utm_campaign: req.query?.utm_campaign,
                oid: req.query?.oid,
                user_identity: req.query?.user_identity,
                platform: "web",
              });
              if (typeof data !== "undefined") {
                return {
                  ...data,
                  accessToken: headers["authorization"].replace("Bearer ", ""),
                };
              } else {
                return null;
              }
            } catch (error) {
              // @ts-ignore
              throw new Error(JSON.stringify(error.response.data));
            }
          }

          if (req.query?.provider === "google_oauth2") {
            try {
              const { data, headers } = await postRegisterGoogle({
                grant_type: req.query?.provider,
                ip_address: ipAddress,
                user_agent: userAgent,
                promocode: req.query?.promocode,
                id_token: req.query?.id_token,
                client_id: req.query?.client_id,
                click_id: req.query?.click_id,
                utm_source: req.query?.utm_source,
                utm_medium: req.query?.utm_medium,
                utm_campaign: req.query?.utm_campaign,
                oid: req.query?.oid,
                platform: "web",
              });
              if (typeof data !== "undefined") {
                return {
                  ...data,
                  accessToken: headers["authorization"].replace("Bearer ", ""),
                };
              } else {
                return null;
              }
            } catch (error) {
              // @ts-ignore
              throw new Error(JSON.stringify(error.response.data));
            }
          }

          if (req.query?.provider === "password") {
            try {
              const { data, headers } = await postRegisterPassword({
                grant_type: req.query?.provider,
                ip_address: ipAddress,
                user_agent: userAgent,
                promocode: req.query?.promocode,
                user: {
                  email: req.query?.email,
                  password: req.query?.password,
                  first_name: req.query?.first_name,
                  last_name: req.query?.last_name,
                  client_id: req.query?.client_id,
                  click_id: req.query?.click_id,
                  utm_source: req.query?.utm_source,
                  utm_medium: req.query?.utm_medium,
                  utm_campaign: req.query?.utm_campaign,
                  oid: req.query?.oid,
                },
                platform: "web",
              });
              if (typeof data !== "undefined") {
                return {
                  ...data,
                  accessToken: headers["authorization"].replace("Bearer ", ""),
                };
              } else {
                return null;
              }
            } catch (error) {
              // @ts-ignore
              throw new Error(JSON.stringify(error.response.data));
            }
          }

          // @ts-ignore
          if (!credentials.provider) {
            try {
              const { data, headers } = await postLoginEmail({
                user: {
                  email: credentials.email,
                  password: credentials.password,
                },
              });
              if (typeof data !== "undefined") {
                return {
                  ...data,
                  accessToken: headers["authorization"].replace("Bearer ", ""),
                };
              } else {
                return null;
              }
            } catch (error) {
              console.log(error);
              // @ts-ignore
              throw new Error(JSON.stringify(error.response.data));
            }
          }
        } else {
          return null;
        }
      },
    }),
    GoogleProvider({
      clientId: process.env.GOOGLE_ID ?? "",
      clientSecret: process.env.GOOGLE_SECRET ?? "",
      authorization: {
        params: {
          prompt: "consent",
          access_type: "offline",
          response_type: "code",
        },
      },
    }),
    AppleProvider({
      clientId: process.env.APPLE_CLIENT_ID!,
      clientSecret: process.env.APPLE_CLIENT_SECRET!,
      authorization: {
        params: {
          scope: "name email",
          response_mode: "form_post",
          response_type: "code",
        },
      },
    }),
  ],
  pages: {
    newUser: "/register",
    signIn: "/login",
    error: "/login",
  },
  secret: process.env.NEXTAUTH_SECRET,
  session: { strategy: "jwt" },
  cookies: {
    pkceCodeVerifier: {
      name: "next-auth.pkce.code_verifier",
      options: {
        httpOnly: true,
        sameSite: "none",
        path: "/",
        secure: true,
      },
    },
    callbackUrl: {
      name: `__Secure-next-auth.callback-url`,
      options: {
        httpOnly: false,
        sameSite: "none",
        path: "/",
        secure: true,
      },
    },
  },
  callbacks: {
    async jwt({ token, user, account }) {
      if (account) {
        token.id_token = account.id_token;
        token.provider = account.provider;
        token.user_identity = user.id;
      }
      if (user) {
        // @ts-ignore
        const { accessToken, ...rest } = user;
        // @ts-ignore
        token.accessToken = accessToken;
        // @ts-ignore
        token.user = rest;
      }
      return token;
    },

    async session({ session, token }) {
      // @ts-ignore
      session.user = { ...session.user, ...token.user };
      // @ts-ignore
      session.accessToken = token.accessToken;
      // @ts-ignore
      session.id_token = token.id_token;
      // @ts-ignore
      session.provider = token.provider;
      // @ts-ignore
      session.user_identity = token.user_identity;

      return session;
    },
  },
};
